// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$backoffice-primary: mat.define-palette(mat.$indigo-palette);
$backoffice-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$backoffice-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$backoffice-theme: mat.define-light-theme(
  (
    color: (
      primary: $backoffice-primary,
      accent: $backoffice-accent,
      warn: $backoffice-warn,
    ),
  )
);

$custom-primary: mat.define-palette(mat.$indigo-palette);
$custom-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$custom-theme: mat.define-light-theme(
    (
      color: (
        primary: $custom-accent,
        accent: $custom-accent,
      ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($backoffice-theme);
@include mat.form-field-theme($custom-theme);

/* You can add global styles to this file, and also import other style files */

@import "~quill/dist/quill.snow.css";

//WOOTIC CUSTOM COLORS
:root {
  //primary button
  --woo-primary: #0a3d64;
  --woo-primary-text: #fff;
  --woo-primary-hover: #ed54ab;
  --woo-primary-active: #002993;
  --woo-primary-disabled: #f1f1f1;
  --woo-spinner: #babec3;
  --woo-text-medium-grey: #6d7175;
  --woo-danger-input: #d82c0d;
  //basic button
  --woo-basic: #0a3d64;
  --woo-basic-disabled-text: #8c9196;
  --woo-basic-contrast-text: #fff;
  --woo-basic-hover: #0d4977;
  --woo-basic-active: #6d7175;
  // Secondary
  --woo-secondary: #ed54ab;
  //shared
  --woo-grey-detail: #babec3;
  --woo-medium-grey: #929292;
  //backgroud:
  --woo-background-hover: #f2fbfb;
  --woo-background-drag: #f2f7fe;
  --woo-background-white: #ffffff;
  --woo-background-danger: #fff4f4;
  --woo-background-disabled: #fafbfb;

  //border
  --woo-border-error: #fd5749;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  color: var(--woo-secondary) !important;
}

.global-spinner {
  height: 75px;
  width: 75px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.woo-menu-user-info .circle {
  border: 1px solid #ed54ad !important;
  background-color: #ed54ab !important;
}

.bg-black {
  background: linear-gradient(
    180deg,
    #272726 0.01%,
    #272726 0.02%,
    #272726 100%
  );
}

.bg-white {
  background: #fff;
}

.center-content {
  display: grid;
  place-items: center;
}

h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0 !important;
}

h3 {
  //font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: 0.15px !important;
  margin: 0 !important;
}

h4 {
  color: var(--woo-secondary);
  font-size: 13px;
  margin: 0 !important;
}

.field {
  display: flex;

  &.centered {
    align-content: center;
    align-items: center;
  }

  &.single {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "first";
  }

  &.double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "first second";
  }

  &.triple {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "first second third";
  }

  &.custom-field-without-icon {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "first second";
  }

  &.custom-field-with-icon {
    display: grid;
    grid-template-columns: 160px 1fr 25px;
    grid-template-rows: auto;
    grid-template-areas: "first second third";
  }

  &.search-with-actions {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "first second third fourth";
  }

  &.search-with-action {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: "first second third";
  }
}

.flex {
  &-row {
    display: flex;
    flex-direction: row;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-arround {
    justify-content: space-around;
  }

  &-between {
    justify-content: space-between;
  }

  &-evenly {
    justify-content: space-evenly;
  }
}

.align {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.margin {
  margin: 15px !important;

  &-top {
    margin-top: 15px !important;
  }

  &-top-x2 {
    margin-top: 30px !important;
  }

  &-bottom {
    margin-bottom: 15px !important;
  }

  &-left {
    margin-left: 15px !important;
  }

  &-right {
    margin-right: 15px !important;
  }

  &-x {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  &-y {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  &-y-x2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}

.gap- {
  &1 {
    gap: 5px;
    grid-column-gap: 5px;
  }

  &2 {
    gap: 10px;
    grid-column-gap: 10px;
  }

  &3 {
    gap: 15px;
    grid-column-gap: 15px;
  }

  &4 {
    gap: 20px;
    grid-column-gap: 20px;
  }

  &5 {
    gap: 25px;
    grid-column-gap: 25px;
  }
}

.width- {
  &10 {
    width: 10%;
  }

  &20 {
    width: 20%;
  }

  &30 {
    width: 30%;
  }

  &40 {
    width: 40%;
  }

  &50 {
    width: 50%;
  }

  &60 {
    width: 60%;
  }

  &70 {
    width: 70%;
  }

  &80 {
    width: 80%;
  }

  &90 {
    width: 90%;
  }

  &100 {
    width: 100%;
  }
}

.text- {
  &start {
    text-align: start;
  }

  &center {
    text-align: center;
  }

  &end {
    text-align: end;
  }

  &primary {
    color: var(--woo-basic);
  }

  &secondary {
    color: var(--woo-secondary);
  }
}

.two-column-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  article {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

woo-searchbox {
  width: 50%;
  padding: 0 35px;
}

.one-liner {
  height: 1.2rem !important;
  line-height: 1rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.four-liner {
  height: 4.8rem !important;
  line-height: 1rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.snack-error {
  //background-color: mat.get-color-from-palette($cardio-web-app-warn);
  color: white;

  mat-icon {
    margin-right: 10px;
  }

  span {
    background-color: transparent;
  }
}

.snack-success {
  //background-color: mat.get-color-from-palette($cardio-web-app-primary);
  color: black;

  mat-icon {
    margin-right: 10px;
  }

  span {
    background-color: transparent;
  }
}

.upload-dropzone {
  border: 1px dashed #babfc3;
  padding: 1rem;
  min-height: 170px;
  border-radius: 8px;
  margin-bottom: 1.5rem !important;

  .ngx-file-drop__content {
    color: #6d7175 !important;
  }
}

.pointer {
  cursor: pointer;
}

mat-tab-group {
  margin-bottom: 15px !important;
}

.woo-menu-items .menu-item.active {
  color: var(--woo-primary-hover) !important;
}

.no-result {
  width: 200px;
  align-self: center;
  text-align: center;
  margin-bottom: 2.5rem !important;
}

woo-button-basic {
  --woo-border: #BABFC3;
}
